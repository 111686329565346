import React from 'react';
import Gallery from "react-photo-gallery";
import Timetab1 from '../../img/timetab/screenshot1.jpeg';
import Timetab2 from '../../img/timetab/screenshot2.jpeg';
import Timetab3 from '../../img/timetab/screenshot3.jpeg';
import Timetab4 from '../../img/timetab/months_columns.png';
import { Link } from "react-router-dom";

import './Project.css';


function Timetab() {

    const photos = [
        {
            src: Timetab1,
            width: 1280,
            height: 800
        },
        {
            src: Timetab2,
            width: 1280,
            height: 800
        },
        {
            src: Timetab3,
            width: 1280,
            height: 800
        },
        {
            src: Timetab4,
            width: 1280,
            height: 800
        }
    ];
    return (
        <div className="photos">
            <p>Timetab is powerful Chrome extension that enables a beautiful new tab page designed to help you take control of your time and stay on top of your schedule and life. Some features are:</p>
            <ul>
                <li>Displays for the <strong>current time, sunrise, sunset, and moon phase</strong>, allowing you to stay in tune with natural rhythms.</li>
                <li>Easy <strong>timer creation</strong>, so you can stay on top of deadlines and focus time</li>
                <li><strong>Changing backgrounds</strong> that reflect the current time of day, multiple themes to choose from, and a dark mode that's easy on the eyes.</li>
                <li>Day in <strong>10-minute blocks view</strong>, which helps you visualize your day and stay productive.</li>
                <li><strong>Year progress, months columns, next holiday and life calendar views</strong>, which help you keep track of your long-term goals.</li>
                <li>Possibility to <strong>import public holidays</strong> of your country and add your custom holidays or free days</li>
                <li>Availability in <strong>English, Spanish, and German</strong>, making it accessible to users around the world.</li>
            </ul>
            <p>
            <Link to="https://chrome.google.com/webstore/detail/timetab/liobdfgkcgpgfeoemlcoelichpjpbkhe" ><button className='gradient-button'>Download Extension</button> </Link>
            </p>
            <Gallery photos={photos} direction={"row"} targetRowHeight={300} />
        </div>
    );
}

export default Timetab;
