import React from 'react';
import logo from './img/logo_white.png';
import tubirra_back from './img/tubirra_background.png';
import logo_timetab from './img/logos/timetab.png';
import logo_deltalevels from './img/logos/deltalevels.png';
import logo_tubirra from './img/logos/tubirra.png';
import './App.css';
import { Switch, Route, Link, useLocation, useParams } from "react-router-dom";
import { TuBirra, Timetab } from "./pages/projects";

import _ from "lodash";

/*import GitHubIcon from '@material-ui/icons/GitHub';*/
/*import MailOutlineIcon from '@material-ui/icons/MailOutline';*/
/*import InstagramIcon from '@material-ui/icons/Instagram';*/

function Header() {

  const location = useLocation();
  const section = location.pathname.split("/")[1]

  return (
    <header>
      <div className="header-container">
      <div className="logo-container"><Link to="/"><img className="logo" src={logo} alt="Logo" /></Link></div>
      <nav>
        <ul>
          <li className={section === "" ? "nav--active" : ""}>
            <Link to="/">Home</Link>
          </li>
          <li className={section === "projects" ? "nav--active" : ""}>
            <Link to="/projects">Projects</Link>
          </li>
          {/* <li className={section === "art" ? "nav--active" : ""}>
            <Link to="/art">Art</Link>
          </li> 
          <li className={section === "about" ? "nav--active" : ""}>
            <Link to="/about">About</Link>
          </li>*/}
          {/*<li className={section === "opensea" ? "nav--active" : ""}>
            <Link to={{ pathname: "https://opensea.io/munger-art" }} target="_blank">OpenSea</Link>
          </li>*/}
          {/*<li className="social-icons">
            <a href="https://instagram.com/munger.art" target="_blanck" ><InstagramIcon fontSize="small" /></a>
            <a href="mailto:matiunger@gmail.com" style={{"marginRight" : "6dpx"}}><MailOutlineIcon fontSize="small" /></a>
          </li>*/}
        </ul>
      </nav>
      </div>
    </header>
  );

}

function Footer() {

  const location = useLocation();
  const section = location.pathname.split("/")[1]

  return (
    <footer>
    <div className="footer-container">
      <div></div>
      <nav>
        <ul>
          <li className={section === "" ? "nav--active" : ""}>
            <Link to="/">Home</Link>
          </li>
          <li className={section === "projects" ? "nav--active" : ""}>
            <Link to="/projects">Projects</Link>
          </li>
          {/* <li className={section === "art" ? "nav--active" : ""}>
            <Link to="/art">Art</Link>
          </li> 
          {/*<li className={section === "about" ? "nav--active" : ""}>
            <Link to="/about">About</Link>
          </li>*/}
          {/*<li className={section === "opensea" ? "nav--active" : ""}>
            <Link to={{ pathname: "https://opensea.io/munger-art" }} target="_blank">OpenSea</Link>
          </li>*/}
          {/*<li className="social-icons">
            <a href="https://instagram.com/munger.art" target="_blanck" ><InstagramIcon fontSize="small" /></a>
            <a href="mailto:matiunger@gmail.com" style={{"marginRight" : "6dpx"}}><MailOutlineIcon fontSize="small" /></a>
          </li>*/}
        </ul>
      </nav>
    </div>
  </footer>
  )

}

function Entry(props) {
  const data = props.data;
  const path = "/" + data.category + "/" + data.id;

  const is_even = (props.index + 1) % 2 === 0;

  const styles = {
    background: data.background,
    // backgroundImage: `url(${data.background})`,
  };

  let logo = data.icon;
  if (data.logo !== "") {
    logo = <img src={data.logo} alt={data.name} className='h-8 w-8 m-auto' />;
  }

  const buttons = data.actions.map((action, index) => {
    if (action.link !== "") {
      const buttonSize = data.actions.length > 1 ? 'px-3 py-2 text-sm' : 'px-4 py-2';
      return (
        <a key={index} href={action.link} rel="noreferrer">
          <button className={`border border-1 border-white ${buttonSize} rounded-full bg-slate-50 text-slate-800`}>
            {action.calltoaction}
          </button>
        </a>
      );
    }
    return null;
  });

  return (
    <div className="rounded-2xl box-border group duration-200 hover:scale-[1.02] shadow hover:shadow-lg" style={styles}>
      <div className="p-10">
        <div className="flex flex-row gap-4 font-bold text-lg">
          <span className="h-12 w-12 rounded-full bg-white flex items-center duration-200 group-hover:-rotate-12">{logo}</span>
          <span className='flex flex-col justify-center text-white text-2xl'>{data.name}</span>
        </div>
        <p className="text-white text-base mt-4 text-left">{data.description}</p>
        <p className="text-left mt-4 flex flex-row gap-2 flex-wrap">{buttons}</p>
      </div>
    </div>
  );
}

function List(props) {

  const entries = props.entries
  let filteredEntries = entries
  if (props.category !== "home") {
    filteredEntries = _.filter(entries, function (o) { return o.category === props.category }) || []
  }
  return (
    <div className='max-w-4xl mx-auto'>
      <div className="entries-separator"></div>
      <div className="flex flex-col px-10 md:grid md:grid-cols-2 gap-6 py-10" style={props.style}>
        {filteredEntries.map((item, index) => { return <Entry key={item.id} index={index} data={item} /> })}
      </div>
    </div>
  )

}

function Page(props) {
  const { c, p } = useParams()
  let filteredEntry = _.filter(props.entries, function (o) { return (o.category === c && o.id === p) }) || []
  const entry = filteredEntry[0]
  const EntryComponent = entry["component"]
  
  return <div className="page" style={props.style}>
    <h2 className="page__title">{entry["name"]}</h2>
    <EntryComponent />
    </div>;
}

/*
function Home(props) {

  const styles = { backgroundImage: `linear-gradient(${235}deg, #aae81a, #aae81a00 70.71%),linear-gradient(${235-120}deg, #038544, #b3e5be00 70.71%),linear-gradient(${235+120}deg, #03da7d, #9785ff00 70.71%)`, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}

  const [background, setBackground] = React.useState(styles);

  const handleMouseMove = (e) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    // Get the div element and its center position
    const divElement = document.getElementById('hero-title');
    const divBounds = divElement.getBoundingClientRect();
    const divCenterX = divBounds.left + divBounds.width / 2;
    const divCenterY = divBounds.top + divBounds.height / 2;

    // Calculate the angle in radians between the div center and the mouse position
    const angleRadians = Math.atan2(mouseY - divCenterY, mouseX - divCenterX);

    // Convert the angle from radians to degrees
    const angleDegrees = angleRadians * 180 / Math.PI - 90;
    const back_grad = {  backgroundImage: `linear-gradient(${angleDegrees}deg, #aae81a, #aae81a00 70.71%),linear-gradient(${angleDegrees-120}deg, #038544, #b3e5be00 70.71%),linear-gradient(${angleDegrees+120}deg, #03da7d, #9785ff00 70.71%)`,WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}

    setBackground(back_grad);
  }

  return (
    <div className="page" style={props.style} onMouseMove={handleMouseMove}>
      <div className="header-hero">
        <h1 id="hero-title" key={background} style={background}>Leaping into the future, <br />one idea at a time.</h1>
      </div>
    <List category="home" entries={props.entries} />
    </div>
  )
}
*/
function Home(props) {
  const [background, setBackground] = React.useState();

  React.useEffect(() => {
    let requestId;
    const divElement = document.getElementById('hero-title');
    const divBounds = divElement.getBoundingClientRect();
    const divCenterX = divBounds.left + divBounds.width / 2;
    const divCenterY = divBounds.top + divBounds.height / 2;

    const updateBackground = () => {
      const mouseX = window.mouseX;
      const mouseY = window.mouseY;

      // Calculate the angle in radians between the div center and the mouse position
      const angleRadians = Math.atan2(mouseY - divCenterY, mouseX - divCenterX);

      // Convert the angle from radians to degrees
      const angleDegrees = angleRadians * 180 / Math.PI - 90;

      const back_grad = {
        backgroundImage: `linear-gradient(${angleDegrees}deg, #aae81a, #aae81a00 70.71%),linear-gradient(${angleDegrees - 120}deg, #038544, #b3e5be00 70.71%),linear-gradient(${angleDegrees + 120}deg, #03da7d, #9785ff00 70.71%)`,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
      };

      if (JSON.stringify(back_grad) !== JSON.stringify(background)) {
        setBackground(back_grad);
      }

      requestId = requestAnimationFrame(updateBackground);
    };

    requestId = requestAnimationFrame(updateBackground);

    return () => cancelAnimationFrame(requestId);
  }, [background]);

  React.useEffect(() => {
    const handleMouseMove = (e) => {
      window.mouseX = e.clientX;
      window.mouseY = e.clientY;
    };

    //window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className="page_home"
      style={props.style}
    >
      <div className="header-hero">
        <h1
          id="hero-title"
          className='text-4xl sm:text-5xl text font-bold'
          key={JSON.stringify(background)}
          style={background}
        >
          Shaping the future, <br />
          one idea at a time.
        </h1>
        <div className="header-tagline">Poing Labs is a springboard to launch new ideas into reality.</div>
      </div>
      <List category="home" entries={props.entries} />
    </div>
  );
}


function About(props) {
  return <div className="page" style={props.style}>
    <h2 className="page__title">Welcome :)</h2>
    <p>Poing is an innovation lab dedicated to bringing new ideas and products to life.</p>
    <p>We believe in developing solutions that make a positive impact, <br />addressing specific needs or simply providing entertainment.</p>
    
    
    
    </div>;
}

function App() {

  const location = useLocation();

  const entries = [
    {
      "id": "tubirra",
      "name": "PickNudge",
      "description": "Digital and interactive QR menu offering tailored recommendations.",
      "category": "projects",
      "icon": "🍺",
      "logo": logo_tubirra,
      "background": "#fcbf49",
      "actions": [
        { "calltoaction": "picknudge", "link": "https://picknudge.com" },
        { "calltoaction": "beernudge", "link": "https://beernudge.com" },
        { "calltoaction": "tubirra", "link": "https://tubirra.com" }
      ],
      "component": TuBirra,
    },
    {
      "id": "deltalevel",
      "name": "DeltaLevels",
      "description": "Real time, forecast and historical levels of the BA delta river",
      "category": "projects",
      "icon": "",
      "logo": logo_deltalevels,
      "background": "#B3D7AB",
      "actions": [
        { "calltoaction": "Try it out", "link": "https://deltalevels.com" }
      ],
      "component": "",
    },
    {
      "id": "timetab",
      "name": "Timetab",
      "description": "Beautiful new tab page to take control of your time.",
      "category": "projects",
      "icon": "⌛",
      "logo": logo_timetab,
      "background": "#8b87de",
      "actions": [
        { "calltoaction": "Read more", "link": "https://timetab.poinglabs.com" }
      ],
      "component": Timetab,
    },
    
  ]

  return (
    <div className="App">
      <Header />
      <div className="routes">
            <Switch location={location}>
              <Route exact path="/"><Home entries={entries} /></Route>
              <Route exact path="/projects"><List category="projects" entries={entries} /></Route>
              <Route exact path="/art"><List category="art" entries={entries} /></Route>
              <Route exact path="/:c/:p"><Page entries={entries} /></Route>
              <Route exact path="/about"><About /></Route>
            </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;



